<template>
  <v-form ref="form">
    <v-card class="pb-10">
      <v-row justify="center">
        <v-col cols="4" md="5">
          <v-responsive class="pt-10">
            <v-img width="300px" src="/logo-black.jpeg"></v-img>
          </v-responsive>
        </v-col>
      </v-row>
      <v-card-title class="subtitle-1 text-center justify-center pb-10 px-12">
        {{ texts.title }}
      </v-card-title>
      <v-card-text class="px-10">
        <v-alert
          v-show="showError"
          dense
          outlined
          type="error"
          class="text-center"
          v-html="error"
        >
          {{ error }}
        </v-alert>
        <v-text-field
          v-if="isLogin"
          v-model="user.email"
          label="email"
          outlined
          name="email"
          :prepend-inner-icon="isLogin ? 'email' : ''"
          type="text"
          dense
          required
          :error-messages="emailErrors"
          v-model.trim="$v.user.email.$model"
        ></v-text-field>
        <v-text-field
          v-else
          v-model="emailOrMobilePhone"
          :placeholder="`${$t('email_mobile_number')}`"
          outlined
          type="text"
          dense
          required
          :rules="requiredRules"
        ></v-text-field>
        <v-text-field
          v-if="isLogin"
          v-model="user.password"
          :label="$t('Password')"
          outlined
          name="password"
          prepend-inner-icon="lock"
          type="password"
          dense
          :error-messages="passwordErrors"
          v-model.trim="$v.user.password.$model"
        ></v-text-field>
        <!-- <v-card-actions
          v-if="isLogin"
        > -->
        <v-btn
          v-if="isLogin"
          :loading="isLoading"
          :disabled="$v.$invalid || isLoading"
          type="submit"
          block
          color="primary"
          large
          @click.prevent="submit"
          >{{ $t("submit_test") }}</v-btn
        >
        <!-- </v-card-actions> -->
        <div v-else class="d-flex flex-column align-center">
          <v-btn
            :disabled="isLoading"
            color="primary"
            class="mb-3"
            block
            @click.prevent="submit"
            >{{ $t("search") }}</v-btn
          >
          <v-btn
            class="mx-2"
            type="reset"
            block
            @click.prevent="cancelRecover()"
            >{{ $t("cancel") }}</v-btn
          >
        </div>
        <v-col v-if="isLogin" class="pt-10">
          <p class="text-center text--disabled mb-0">
            {{ $t("forgot_password") }}?
            <span
              class="primary--text recover-login"
              @click="isLogin = !isLogin"
              >{{ $t("recover") }}</span
            >
          </p>
        </v-col>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { LOGIN_MUTATION } from "./../graphql/login";
import { GET_TRANSACTIONS_BY_ENTITY_ID } from "./../graphql/query.gql";
import { SEARCH_USER_BY_EMAIL_OR_MOBILE_PHONE } from "./../graphql/mutation.gql";
import { formatError } from "@/utils";
import { GC_AUTH_TOKEN, GC_USER_ID, LANG } from "@/constants/settings";
import { mapActions } from "vuex";
import accessLevelMixins from "@/mixins/access-level";
export default {
  name: "LoginForm",
  mixins: [accessLevelMixins],
  data: () => ({
    emailOrMobilePhone: "",
    error: undefined,
    noInternet: false,
    isLoading: false,
    isLogin: true,
    isRecover: false,
    showError: false,
    user: {
      name: "",
      email: "",
      password: "",
    },
  }),

  validations() {
    const validations = {
      user: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(6),
        },
      },
    };

    return validations;
  },

  apollo: {
    modules: {
      query: GET_TRANSACTIONS_BY_ENTITY_ID,
      variables() {
        return { entityId: this.entityId };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.setModules(data.transactions);
          localStorage.setItem("modulesL", JSON.stringify(data.transactions));
        }
      },
    },
  },

  created() {
    this.noInternet = window.navigator.onLine ? false : true;
    // this.currentFlag = localStorage.getItem("flag")
    //   ? localStorage.getItem("flag")
    //   : "pt";
    if (this.noInternet) {
      this.error = "No Internet Connection";
      this.showError = true;
    }
  },

  computed: {
    emailErrors() {
      const errors = [];
      const email = this.$v.user.email;
      if (!email.$dirty) {
        return errors;
      }
      !email.required && errors.push(`${this.$t("email_required")}!`);
      !email.email && errors.push(`${this.$t("enter_valid_email")}!`);

      return errors;
    },
    passwordErrors() {
      const errors = [];
      const password = this.$v.user.password;
      if (!password.$dirty) {
        return errors;
      }
      !password.required && errors.push(`${this.$t("password_required")}!`);
      !password.minLength &&
        errors.push(
          `${this.$t("enter_least")} ${
            password.$params.minLength.min
          } ${this.$t("characters")}!`
        );

      return errors;
    },
    requiredRules() {
      return [(v) => !!v || `${this.$t("email_required")}!`];
    },
    texts() {
      return this.isLogin
        ? { title: "Login", button: this.$t("btn_login") }
        : {
            title: `${this.$t("enter_email_user")}`,
            button: `${this.$t("submit_test")}`,
          };
    },
  },

  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
      setModules: "auth/setModules",
    }),
    cancelRecover() {
      this.isLogin = !this.isLogin;
    },
    async redirectEntity(user) {
      this.entityId = user.entity.id;
      this.$apollo.queries.modules.skip = false;
      await this.$apollo.queries.modules.refetch();
      if (user.entity.status) {
        return this.$router.push(
          this.$route.query.redirect || this.setRoute(user)
        );
      }
      const link = `https://createaccount.whirelab.com/billing.html?entityId=${user.entity.id}&moduleId=${user.entity.modules[0].module.id}`;
      // const link = `https://webpage2.whirelab.com/billing.html?entityId=${user.entity.id}&moduleId=${user.entity.modules[0].module.id}`;
      // const link = `http://127.0.0.1:5500/whirelab-create-entity/billing.html?entityId=${user.entity.id}&moduleId=${user.entity.modules[0].module.id}`;
      this.error = this.$t(`complete_your_subscription`, {
        subscription: `<a href="${link}" style="cursor: pointer;">${this.$t(
          "subscription"
        )}</a>`,
      });
      // this.error = `Conclua a sua  para poder continuar`;
      this.showError = true;
    },
    async setModulesState(entityId) {
      const { data } = await this.$apollo.mutate({
        mutation: GET_TRANSACTIONS_BY_ENTITY_ID,
        variables: {
          entityId,
        },
      });
      this.setModules(data.getTransactionsByEntityId);
    },
    setRoute(user) {
      if (this.accessLevel(user, "dashboard")) {
        return "dashboard";
      }
      return "/dashboard/entity/user/profile";
    },
    saveUserData(id, token) {
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);
      localStorage.setItem(LANG, this.$i18n.locale);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
    },
    async submit() {
      this.isLoading = true;
      if (this.isLogin) {
        try {
          await this.$apollo
            .mutate({
              mutation: LOGIN_MUTATION,
              variables: {
                ...this.user,
              },
            })
            .then(({ data }) => {
              const id = data.login.user.id;
              const token = data.login.token;
              this.saveUserData(id, token);
              this.setUserState(data.login.user);
              let loginUrl = localStorage.getItem("loginUrl")
                ? "/candidate" + localStorage.getItem("loginUrl")
                : localStorage.getItem("loginParams")
                ? "/candidate/"
                : "/candidate";
              switch (data.login.user.type) {
                case "candidate":
                  this.$router.push(this.$route.query.redirect || loginUrl);
                  break;
                case "entity":
                  this.redirectEntity(data.login.user);
                  break;
                case "partner":
                  this.$router.push(this.$route.query.redirect || "/partner");
                  break;
                case "root":
                  this.$router.push(this.$route.query.redirect || "/root");
                  break;
              }
            });
        } catch (error) {
          this.error = formatError(error.message);
          switch (this.error) {
            case "Email":
              this.error = this.$t("enter_valid_email");
              break;

            default:
              this.error = this.$t("email_password_incorrect");
              break;
          }
          this.noInternet = window.navigator.onLine ? false : true;
          if (this.noInternet) {
            this.error = "No Internet Connection";
          }
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      } else {
        if (this.emailOrMobilePhone) {
          try {
            const { data } = await this.$apollo.mutate({
              mutation: SEARCH_USER_BY_EMAIL_OR_MOBILE_PHONE,
              variables: {
                emailOrMobilePhone: this.emailOrMobilePhone,
              },
            });

            const findedType = this.emailOrMobilePhone.search("@");
            this.$router.push({
              path: `recover_password`,
              query: {
                userAccount: { ...data.findedAccount },
                typeEnter: { type: findedType > -1 ? "email" : "number" },
              },
            });
          } catch (error) {
            this.error = formatError(error.message);
            this.showError = true;
          } finally {
            this.isLoading = false;
          }
        } else {
          this.error = this.$t("email_required");
          this.showError = true;
          this.isLoading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.recover-login {
  cursor: pointer;
}
</style>
